import React from "react"

import {LinkedinSquare, FacebookSquare, Twitter} from "@styled-icons/boxicons-logos"

const currentYear = new Date().getFullYear();
  
  export default function Footer() {
    return (
      <footer className="bg-slate-500 font-light">
        <div className="text-gray-300 justify-center max-w-7xl mx-auto pt-12 px-4 sm:px-6 lg:px-8">
          <p>Koudepouce vous guide en tant que consommateur dans vos choix. Vous trouverez sur notre site internet des informations sur des thématiques diverses.</p>
          <p>
            Bénéficiez aussi d'un service de mise en relation avec des entreprises.<br />
            <a href="/legal/mentions-legales/" className="text-sky-300 underline">Mentions légales</a> &amp; <a href="/contact/" className="text-sky-300 underline">Contact</a>
          </p>
        </div>
        <div className="max-w-7xl mx-auto pb-12 pt-6 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
          <div className="flex justify-center space-x-6 md:order-2">

              <a href="https://www.facebook.com/koudepouce.fr" className="text-gray-300 hover:text-gray-200">
                <span className="sr-only">Facebook</span>
                <FacebookSquare className="h-6 w-6" aria-hidden="true" />
              </a>

              <a href="https://twitter.com/koudepouce" className="text-gray-300 hover:text-gray-200">
                <span className="sr-only">Twitter</span>
                <Twitter className="h-6 w-6" aria-hidden="true" />
              </a>

              <a href="https://www.linkedin.com/company/81753620/admin/" className="text-gray-300 hover:text-gray-200">
                <span className="sr-only">LinkedIn</span>
                <LinkedinSquare className="h-6 w-6" aria-hidden="true" />
              </a>

          </div>
          <div className="mt-8 md:mt-0 md:order-1">
            <p></p>
            <p className="text-center text-base text-gray-300"><span className="font-bold">Koudepouce</span> 97 Allée Alexandre Borodine (Bâtiment Woodclub) 69800 St Priest - &copy; {currentYear} Tous Droits Réservés.</p>
          </div>
        </div>
      </footer>
    )
  }
  