import React from 'react'
/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  XIcon,
  ChevronRightIcon
} from '@heroicons/react/outline'

import { StaticImage } from "gatsby-plugin-image"



export default function Header() {
  return (
    <Popover className="px-4 relative bg-white max-w-7xl mx-auto">
      <div className="h-24 flex justify-between items-center py-2 md:justify-start md:space-x-10">
        <div>
          <a href="/" className="flex">
            <span className="sr-only">Koudepouce</span>
             <StaticImage
              src="../../images/koudepouce-logo.png" 
              alt="Koudepouce"
              height={75}
              width={200}
            />
          </a>
        </div>
        <div className="-mr-2 -my-2 md:hidden">
          <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
            <span className="sr-only">Ouvrir menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
          <Popover.Group as="nav" className="flex space-x-10">
            
            <a href="/maison/" className="text-xl font-light text-gray-500 hover:text-gray-900">
              Maison
            </a>
            <a href="/droit/" className="text-xl font-light text-gray-500 hover:text-gray-900">
              Droit
            </a>
            <a href="/sante/" className="text-xl font-light text-gray-500 hover:text-gray-900">
              Sante
            </a>
            <a href="/argent/" className="text-xl font-light text-gray-500 hover:text-gray-900">
              Argent
            </a>
            <a href="/vehicules/" className="text-xl font-light text-gray-500 hover:text-gray-900">
              Véhicule
            </a>

          </Popover.Group>

          <div className="flex items-center md:ml-12">
            <a
              href="/contact/"
              className="ml-8 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-amber-600 hover:bg-amber-700"
            >
              Devis et solutions
            </a>
          </div>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel focus className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6">
              <div className="flex items-center justify-between">
                <div>
                  <StaticImage
                    src="../../images/koudepouce-logo.png" 
                    alt="Koudepouce"
                    height={75}
                    width={200}
                  />
                </div>
                <div className="-mr-2 px-4">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
                    <span className="sr-only">Ouvrir menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="py-6 px-5">
              <div className="grid gap-4">
                <a href="/maison/" className="text-xl font-light text-gray-500 hover:text-gray-900">
                  <ChevronRightIcon className="w-5 h-5 inline mb-1 mr-2" />Maison
                </a>
                <a href="/droit/" className="text-xl font-light text-gray-500 hover:text-gray-900">
                  <ChevronRightIcon className="w-5 h-5 inline mb-1 mr-2" />Droit
                </a>
                <a href="/sante/" className="text-xl font-light text-gray-500 hover:text-gray-900">
                  <ChevronRightIcon className="w-5 h-5 inline mb-1 mr-2" />Sante
                </a>
                <a href="/argent/" className="text-xl font-light text-gray-500 hover:text-gray-900">
                  <ChevronRightIcon className="w-5 h-5 inline mb-1 mr-2" />Argent
                </a>
                <a href="/vehicules/" className="text-xl font-light text-gray-500 hover:text-gray-900">
                  <ChevronRightIcon className="w-5 h-5 inline mb-1 mr-2" />Véhicule
                </a>
              </div>
              
            </div>
            <div className="flex items-center md:ml-12">
              <a
                href="/contact/"
                className="w-full mx-4 mb-4 inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-amber-600 hover:bg-amber-700"
              >
                Devis et solutions
              </a>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
